import {CREATE_STREAM, SEND_OTP} from '../actions/types';

const initializestate={
    isloading:"false"
};

export default (state=initializestate,action) => {
  
    switch (action.type) {
        case CREATE_STREAM :
            return action.payload;
        case SEND_OTP :
            return action.payload;
            default:
                return state;
    }
}