import React from 'react'
import { useToasts } from 'react-toast-notifications'
import { Field, reduxForm } from 'redux-form'
import { signIn } from '../actions/index'
import { connect } from 'react-redux'
import axios from 'axios'

const Contact = (props) => {
	const [msgSent, setMsgSent] = React.useState(false)
	const { addToast } = useToasts()

	const renderInput = ({
		input,
		label,
		type,
		className,
		iclassName,
		meta,
		maxLength,
		minLength,
		placeholder,
	}) => {
		const classNameNam = `field ${meta.error && meta.touched ? 'error' : ''}`

		return (
			<div className={classNameNam}>
				<label>{label}</label>
				<div className={className}>
					<input
						className="form-control"
						type={type}
						size={100}
						minLength={minLength}
						maxLength={maxLength}
						placeholder={placeholder}
						{...input}
					/>
					<i className={iclassName} />
				</div>
			</div>
		)
	}

	const onSubmit = (formValues) => {
		const data = {
			to: formValues.email,
			subject: formValues.subject,
			message: formValues.message,
			name: formValues.name,
			mobNum: formValues.number,
		}
		// axios
		// 	.post(
		// 		'https://5giwu05zu3.execute-api.ap-south-1.amazonaws.com/default/sendEmail',
		// 		// 'https://5giwu05zu3.execute-api.ap-south-1.amazonaws.com/stgEmailService',

		// 		{
		// 			to: formValues.email,
		// 			subject: formValues.subject,
		// 			message: formValues.message,
		// 			name: formValues.name,
		// 			mobNum: formValues.number,
		// 		},
		// 		{
		// 			headers: {
		// 				ContentType: 'application/json',
		// 				// 'Content-Type': 'text/plain;charset=utf-8',
		// 				// 'Content-Type': 'application/x-www-form-urlencoded',
		// 				'Access-Control-Allow-Origin': '*',
		// 				'Access-Control-Allow-Methods': 'GET, POST, DELETE, *',
		// 				'Access-Control-Allow-Headers': 'Content-Type',
		// 			},
		// 		}
		// 	)

		const url =
			'https://5giwu05zu3.execute-api.ap-south-1.amazonaws.com/default/sendEmail'
		fetch(url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'no-cors', // no-cors, *cors, same-origin
			credentials: 'omit',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(() => {
				addToast('Enquiry Sent!', { appearance: 'success', autoDismiss: true })
				setMsgSent(!msgSent)
			})
			.catch((err) => {
				addToast('Failed to send Enquiry', {
					appearance: 'error',
					autoDismiss: true,
				})
				console.error(err)
			})
		console.log(JSON.stringify(data))
		console.log('toast')
	}
	return (
		<div>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-6">
						<div className="contact-content mb-50">
							<h6>Contact Us</h6>
							<h2>We'd love to hear from you</h2>
							{/* <p style={{color: "#747794",
           "lineHeight": "1.8",
           "fontSize": "1rem"}}>Content need to be get and replace here </p> */}
						</div>
					</div>

					<div className="col-12 col-md-6">
						<div className="contact_from mb-50">
							<div className="contact_input_area">
								<div id="success_fail_info"></div>
								<div className="row">
									<form
										className="ui form error"
										onSubmit={props.handleSubmit(onSubmit)}
									>
										<div className="col-6">
											<div className="form-group">
												<Field
													name="name"
													component={renderInput}
													type="text"
													placeholder="Your Name"
												/>
											</div>
										</div>

										<div className="col-8">
											<div className="form-group">
												<Field
													name="number"
													component={renderInput}
													type="number"
													placeholder="Your Number*"
												/>
											</div>
										</div>

										<div className="col-10">
											<div className="form-group">
												<Field
													name="email"
													component={renderInput}
													type="email"
													placeholder="Your E-mail"
												/>
											</div>
										</div>

										<div className="col-10">
											<div className="form-group">
												<Field
													name="subject"
													component={renderInput}
													type="text"
													placeholder="Your Subject"
												/>
											</div>
										</div>

										<div className="col-10">
											<div className="form-group">
												<Field
													name="message"
													component={renderInput}
													type="text"
													placeholder="Your Message*"
													height={30}
												/>
											</div>
										</div>
										<div className="col-12 mt-30">
											<button
												className=" apland-btn"
												style={{ backgroundColor: 'black', color: 'white' }}
											>
												{msgSent ? 'Message Sent' : 'Send Message'}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const validate = (formValues) => {
	// console.log(formValues);

	const errors = {}

	if (!formValues.name) {
		errors.name = 'Name should not empty'
	}
	if (!formValues.email) {
		errors.email = 'Email Address should not empty'
	}
	if (!formValues.subject) {
		errors.pswd = 'Password should not empty'
	}

	if (!formValues.number) {
		errors.rPswd = 'Re-enter Password should not empty'
	}
	if (formValues.pswd !== formValues.rPswd) {
		errors.rPswd = 'Password should be same'
	}
	if (!formValues.message) {
		errors.mobNo = 'Please enter Mobile Number'
	}

	return errors
}

const formWrapped = reduxForm({
	form: 'contactus',
	validate,
})(Contact)

export default connect(null, { signIn })(formWrapped)
