import streams from '../apis/streams'
import { CREATE_STREAM, SEND_OTP } from './types'

export const signIn = (formValues) => async (dispatch) => {
	const response = await streams.post(
		'/v1/brodcast/send_email',
		{
			to: `pingus@amoghatech.co`,
			subject: `New enquiry Request`,
			body: btoa(`
            Name: ${formValues.name}
            Email: ${formValues.email}
            Mobile: ${formValues.number}
            Subject: ${formValues.subject}
            Message: ${formValues.message}
        `),
		},
		{
			headers: {
				'Content-Type': 'text/plain;charset=utf-8',
			},
		}
	)

	console.log(response)
}
