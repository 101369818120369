import React, { Component } from 'react'
import WOW from 'wowjs'
import Lottie from 'react-lottie'
import { HashLink as Link } from 'react-router-hash-link'
import FreshChat from 'react-freshchat'
import { ToastProvider } from 'react-toast-notifications'

import './App.css'
import './assets/css/hex.css'
import './assets/css/style.css'
import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/icofont/icofont.min.css'
import './assets/vendor/boxicons/css/boxicons.min.css'
import './assets/vendor/animate.css/animate.min.css'
import './assets/vendor/remixicon/remixicon.css'
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import './assets/vendor/venobox/venobox.css'
import './assets/vendor/aos/aos.css'

import './assets/fonts/Avenir Next.ttc'

//image import start
import bytone_logo from './assets/img/Bytone_Logo.svg'
import bytone_tagLine from './assets/img/webDev_illus.svg'
import bytone_logo_footer from './assets/img/BytoneLogo-Transparent.png'
import our_vision from './assets/img/vision1.svg'
import mobile_icon from './assets/img/feature/our-services/artboard1.png'
import computer_icon from './assets/img/feature/our-services/artboard2.png'
import ecommerce_icon from './assets/img/feature/our-services/artboard4.png'
import web_app_icon from './assets/img/feature/our-services/webApp.svg'
import cloud_icon from './assets/img/feature/our-services/cloud_icon.svg'
import analysis_icon from './assets/img/feature/our-services/analysis.svg'
import internet_icon from './assets/img/feature/our-services/internet.svg'
import business_automation from './assets/img/feature/our-services/BPA.jpg'
import cloudification from './assets/img/feature/our-services/cloud.jpg'
import iot from './assets/img/feature/our-services/iot.jpg'
import mobile_dev_img from './assets/Tools_We_Use/mobile_dev.svg'
import web_dev_img from './assets/Tools_We_Use/Web_dev.svg'
import ui_ux_img from './assets/Tools_We_Use/ui ux.svg'
import ecommerce_img from './assets/Tools_We_Use/ecommerce.svg'
import portfolio from './assets/img/portfolio.svg'
import portfolio_heading from './assets/img/global client portfolio.svg'
import portfolio_content from './assets/img/US-UK-India.svg'

import crm_feature_bg from './assets/img/crm-feature-bg2.svg'

import process_animation from './assets/lottie/process.json'

import Carousel from './components/Carousel'
import Contact from './components/Contact'

class App extends Component {
	constructor(props) {
		super(props)

		this.openMobileMenu = this.openMobileMenu.bind(this)
		this.closeMobileMenu = this.closeMobileMenu.bind(this)
		this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
	}
	state = {
		activeClass: 'home',
		showMobileMenu: false,
	}
	componentDidMount() {
		const wow = new WOW.WOW()
		wow.init()
		this.getClass()
		let sections = document.getElementsByTagName('section')
		let navMenu = document.getElementsByClassName('.nav-menu')
		window.onscroll = () => {
			Array.from(sections).forEach((element) => {
				let currentPosition = document.documentElement.scrollTop
				let top = element.offsetTop - 150
				let bottom = top + element.offsetHeight
				if (currentPosition >= top && currentPosition <= bottom) {
					this.getClass(element.id)
				}
			})
		}
	}
	getoffset = async (el) => {
		// var _x = 0;
		var _y = 0
		while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
			//  _x += el.offsetLeft - el.scrollLeft;
			_y += el.offsetTop - el.scrollTop
			el = el.offsetParent
		}
		console.log('y', _y)
		return _y
	}
	getClass = async (elementId) => {
		let activeClass = elementId

		if (activeClass && activeClass !== this.state.activeClass) {
			this.setState({
				activeClass,
			})
		}
	}

	openMobileMenu(e) {
		return this.setState({
			showMobileMenu: true,
		})
	}
	closeMobileMenu(e) {
		return this.setState({
			showMobileMenu: false,
		})
	}
	toggleMobileMenu(e) {
		return this.setState({
			showMobileMenu: !this.state.showMobileMenu,
		})
	}
	render() {
		const processOptions = {
			loop: true,
			autoplay: true,
			animationData: process_animation,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
			},
		}
		console.log(this.state.activeClass)
		return (
			<div className={this.state.showMobileMenu ? 'mobile-nav-active' : ''}>
				<FreshChat
					token="bf90ff9e-b0f3-4f4c-93b5-2cae530f34e6"
					host="https://wchat.in.freshchat.com"
				/>
				<button
					type="button"
					className="mobile-nav-toggle d-lg-none "
					onClick={this.toggleMobileMenu}
					style={{ marginTop: 20 }}
				>
					{this.state.showMobileMenu ? (
						<i className="icofont-close" />
					) : (
						<div className="navigation-menu-icon"></div>
					)}
				</button>
				<div
					className={
						this.state.showMobileMenu ? 'mobile-nav-overly d-block' : 'd-none'
					}
				></div>
				<nav className="mobile-nav d-lg-none">
					<ul>
						<li
							onClick={this.closeMobileMenu}
							className={this.state.activeClass === 'home' ? 'active' : null}
						>
							<Link smooth to="/#">
								Home
							</Link>
						</li>
						<li
							onClick={this.closeMobileMenu}
							className={
								this.state.activeClass === 'services' ? 'active' : null
							}
						>
							<Link smooth to="/#services">
								Services
							</Link>
						</li>
						<li
							onClick={this.closeMobileMenu}
							className={this.state.activeClass === 'works' ? 'active' : null}
						>
							<Link smooth to="#works">
								Our Works
							</Link>
						</li>
						<li
							onClick={this.closeMobileMenu}
							className={this.state.activeClass === 'tech' ? 'active' : null}
						>
							<Link smooth to="#tech">
								Technologies
							</Link>
						</li>
						{/* <li
							onClick={this.closeMobileMenu}
							className={
								this.state.activeClass === 'expertise' ? 'active' : null
							}
						>
							<Link smooth to="/#expertise">
								Areas of Expertise
							</Link>
						</li> */}
						<li
							onClick={this.closeMobileMenu}
							className={this.state.activeClass === 'process' ? 'active' : null}
						>
							<Link smooth to="#process">
								Process
							</Link>
						</li>
						<li
							onClick={this.closeMobileMenu}
							className={this.state.activeClass === 'about' ? 'active' : null}
						>
							<Link smooth to="/#about">
								About
							</Link>
						</li>
						<li
							onClick={this.closeMobileMenu}
							className={this.state.activeClass === 'vision' ? 'active' : null}
						>
							<Link smooth to="/#vision">
								Vision
							</Link>
						</li>
						<li
							onClick={this.closeMobileMenu}
							className={this.state.activeClass === 'contact' ? 'active' : null}
						>
							<Link smooth to="#contact">
								Contact
							</Link>
						</li>
					</ul>
				</nav>
				<header id="header" className="fixed-top">
					<div className="container d-flex  align-items-center pyNone">
						<Link smooth to="/#" className="scrollto logo mr-auto">
							<img alt="bytone-logo" loading="lazy" src={bytone_logo} />
						</Link>
						<nav className="nav-menu d-none d-lg-block">
							<ul>
								<li
									className={
										this.state.activeClass === 'home' ? 'active' : null
									}
								>
									<Link smooth to="/#">
										Home
									</Link>
								</li>

								<li
									className={
										this.state.activeClass === 'services' ? 'active' : null
									}
								>
									<Link smooth to="/#services">
										Services
									</Link>
								</li>

								<li
									className={
										this.state.activeClass === 'works' ? 'active' : null
									}
								>
									<Link smooth to="#works">
										Our Works
									</Link>
								</li>

								<li
									className={
										this.state.activeClass === 'tech' ? 'active' : null
									}
								>
									<Link smooth to="#tech">
										Technologies
									</Link>
								</li>
								{/* <li
									className={
										this.state.activeClass === 'expertise' ? 'active' : null
									}
								>
									<Link smooth to="/#expertise">
										Areas of Expertise
									</Link>
								</li> */}
								<li
									className={
										this.state.activeClass === 'process' ? 'active' : null
									}
								>
									<Link smooth to="#process">
										Process
									</Link>
								</li>
								<li
									className={
										this.state.activeClass === 'about' ? 'active' : null
									}
								>
									<Link smooth to="/#about">
										About
									</Link>
								</li>
								<li
									className={
										this.state.activeClass === 'vision' ? 'active' : null
									}
								>
									<Link smooth to="/#vision">
										Vision
									</Link>
								</li>
								<li
									className={
										this.state.activeClass === 'contact' ? 'active' : null
									}
								>
									<Link smooth to="#contact">
										Contact
									</Link>
								</li>
							</ul>
						</nav>
					</div>
				</header>

				<section id="home" className="dtr-pt-8 bg-blue pb-40">
					<div className="container main-container myHero">
						<div className="row align-items-center justify-content-center ">
							<div className="col wow fadeInLeft">
								<h1 className="title">
									Delivering Web and Mobile Solutions Across Industries
								</h1>
								<p className="description">
									Charting a digital future is no small feat – but we at Bytone
									imagine, deliver, and anchor our technological strategies to
									your business, transforming it digitally.
								</p>
								<div className="heroBtn">
									{/* <button className="contactBtn">CONTACT US</button> */}

									<Link smooth to="#contact" className="pushable">
										<span className="front">CONTACT US</span>
									</Link>

									<Link smooth to="#services" className="pushable">
										<span className="front">KNOW MORE</span>
									</Link>
									{/* <button className="knowMoreBtn">KNOW MORE</button> */}
								</div>
							</div>
							<div className="col">
								<div className="row align-items-center justify-content-center">
									<img
										className="dtr-mb-4 logohome wow fadeInRight web"
										data-wow-delay="0.4s"
										src={bytone_tagLine}
										alt="web development illustration"
										width="600"
										height="300"
										loading="lazy"
									></img>
									<img
										className="dtr-mb-4 logohome wow fadeInRight mobile"
										data-wow-delay="0.4s"
										src={bytone_tagLine}
										alt="web development illustration"
										width="800"
										height="400"
										loading="lazy"
									></img>
								</div>
							</div>
						</div>
					</div>
				</section>

				<main id="main">
					<section className="pb-140 myHero" id="services">
						<div className="section-title">
							<h2>Our Services</h2>
						</div>
						<div className="container">
							<div className="row  justify-content-center mt-15">
								<div className="col-lg-3 ">
									<a className="btn-link" href="#web_dev">
										<div
											className="single-service style--seven text-center wow fadeInRight2"
											data-wow-duration="1s"
											data-wow-delay=".2s"
										>
											<img
												src={web_app_icon}
												alt="computer icon"
												loading="lazy"
												className="pad2"
											></img>
											<div className="service-content">
												<h6
													style={{
														color: 'black',
														marginTop: '1rem',
														marginBottom: '-0.75rem',
													}}
												>
													Web Application Development
												</h6>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3">
									<a className="btn-link" href="#static_dev">
										<div
											className="single-service style--seven text-center wow fadeInRight2"
											data-wow-duration="1s"
											data-wow-delay=".4s"
										>
											<img
												src={computer_icon}
												data-rjs="2"
												alt="ecommerce icon"
												loading="lazy"
												className="pad3"
											></img>
											<div className="service-content">
												<h6 style={{ color: 'black' }}>Static Websites</h6>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3">
									<a className="btn-link" href="#mobile_dev">
										<div
											className="single-service style--seven text-center wow fadeInRight2"
											data-wow-duration="1s"
											data-wow-delay=".1s"
										>
											<img
												src={mobile_icon}
												alt="mobile icon"
												loading="lazy"
												className="pad1"
											></img>
											<div className="service-content">
												<h6 style={{ color: 'black' }}>Mobile Development</h6>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3">
									<a className="btn-link" href="#ecommerce">
										<div
											className="single-service style--seven text-center wow fadeInRight2"
											data-wow-duration="1s"
											data-wow-delay=".4s"
										>
											<img
												src={ecommerce_icon}
												data-rjs="2"
												alt="ecommerce icon"
												loading="lazy"
												className="pad4"
											></img>
											<div className="service-content">
												<h6 style={{ color: 'black' }}>E-Commerce Solutions</h6>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="row  justify-content-center mt-15">
								<div className="col-lg-3">
									<a className="btn-link" href="#cloudification">
										<div
											className="single-service style--seven text-center wow fadeInRight2"
											data-wow-duration="1s"
											data-wow-delay=".1s"
										>
											<img
												src={cloud_icon}
												alt="mobile icon"
												loading="lazy"
												className="pad6"
											></img>
											<div className="service-content">
												<h6 style={{ color: 'black' }}>Cloudification</h6>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3 ">
									<a className="btn-link" href="#iot">
										<div
											className="single-service style--seven text-center wow fadeInRight2"
											data-wow-duration="1s"
											data-wow-delay=".2s"
										>
											<img
												src={internet_icon}
												alt="computer icon"
												loading="lazy"
												className="pad5"
											></img>
											<div className="service-content">
												<h6
													style={{
														color: 'black',
														marginTop: '1rem',
														marginBottom: '-0.75rem',
													}}
												>
													IoT Application Development
												</h6>
											</div>
										</div>
									</a>
								</div>
								<div className="col-lg-3">
									<a className="btn-link" href="#bpa">
										<div
											className="single-service style--seven text-center wow fadeInRight2"
											data-wow-duration="1s"
											data-wow-delay=".4s"
										>
											<img
												src={analysis_icon}
												data-rjs="2"
												alt="ecommerce icon"
												loading="lazy"
												className="pad7"
											></img>
											<div className="service-content">
												<h6
													style={{
														color: 'black',
														marginTop: '1.5rem',
														marginBottom: '-1.5rem',
													}}
												>
													Business Process Automation
												</h6>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</section>

					<section
						id="web_dev"
						className="dtr-sticky-tabs-section marginWeb marginTop-2"
					>
						<div className="container">
							<div className="row1">
								<div
									className="col-12 col-lg-5 text-center  wow fadeInRight "
									data-wow-delay="0.4s"
								>
									<img
										src={web_dev_img}
										alt="web application development"
										loading="lazy"
										className="imgheight"
									></img>
								</div>
								<div
									className="col-12 col-lg-7 dtr-sm-mb-30px wow fadeInLeft"
									data-wow-delay="0.4s"
								>
									<h2 className="title-para left-aligned-title">
										Web Application Development
									</h2>
									<br />
									<p className="dtr-mb-5 title-desc left-aligned-desc">
										We build robust and scalable web applications that bring
										innovative ideas to life. We develop solutions that
										streamline your business operations and are
										customer-oriented. We ensure timely support and maintenance
										so that you can focus on driving your business.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section id="static_dev" className="dtr-sticky-tabs-section margin">
						<div className="container">
							<div className="row2">
								<div
									className="col-12 col-lg-7 dtr-sm-mb-30px wow fadeInRight"
									data-wow-delay="0.4s"
								>
									<h2 className="title-para left-aligned-title">
										Static Website Development
									</h2>
									<br />
									<p className="dtr-mb-5 title-desc left-aligned-desc">
										A website can be the facade of your company in the digital
										world. A good website boosts your credibility and attracts
										new customers. We create highly secure, attractive,
										user-friendly and responsive websites that are tailored to
										your requirements. We also help you create flexible and fast
										single page applications that keep the user in a single
										comfortable web space.
									</p>
								</div>
								<div
									className="col-12 col-lg-5 text-center wow fadeInLeft"
									data-wow-delay="0.4s"
								>
									{/* <img src={ui_ux_img} alt="logo"> </img> */}
									<img
										src={ui_ux_img}
										alt="ui ux development"
										loading="lazy"
										className="imgheight"
									></img>
								</div>
							</div>
						</div>
					</section>

					<section id="mobile_dev" className="dtr-sticky-tabs-section margin">
						<div className="container">
							<div className="row1">
								<div
									className="col-12 col-lg-5 dtr-sm-mb-30px wow fadeInRight text-center"
									data-wow-delay="0.4s"
								>
									<img
										src={mobile_dev_img}
										alt="mobile development"
										loading="lazy"
										className="imgheight"
									></img>
								</div>
								<div
									className="col-12 col-lg-7 dtr-sm-mb-30px wow fadeInLeft"
									data-wow-delay="0.4s"
								>
									<h2 className="title-para left-aligned-title">
										Mobile Development
									</h2>
									<br />
									<p className="dtr-mb-5 title-desc left-aligned-desc">
										{' '}
										At Bytone, we develop both hybrid and native mobile apps. We
										ensure that your custom mobile app gives wings to your
										business ideas. Our team leverages promising features,
										amazing widgets and rich motion APIs to craft the finest
										natively-compiled apps in an accelerated time frame.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section id="ecommerce" className="dtr-sticky-tabs-section">
						<div className="container">
							<div className="row2">
								<div
									className="col-12 col-lg-7 dtr-sm-mb-30px wow fadeInRight"
									data-wow-delay="0.4s"
								>
									<h2 className="title-para left-aligned-title">
										E-Commerce Solutions
									</h2>
									<br />
									<p className="dtr-mb-5 title-desc left-aligned-desc">
										E-commerce has revolutionised the process of shopping and
										has transformed the way consumers purchase their goods and
										services. E-commerce sites work like a charm in expanding
										clientele and we make sure your site stands out in the
										crowd.
									</p>
								</div>
								<div
									className="col-12 col-lg-5 text-center wow fadeInLeft"
									data-wow-delay="0.4s"
								>
									<img
										src={ecommerce_img}
										alt="ecommerce solutions"
										loading="lazy"
										className="imgheight"
									></img>
								</div>
							</div>
						</div>
					</section>

					<section
						id="cloudification"
						className="dtr-sticky-tabs-section margin"
					>
						<div className="container">
							<div className="row1">
								<div
									className="col-12 col-lg-5 text-center wow fadeInRight"
									data-wow-delay="0.4s"
								>
									<img
										src={cloudification}
										alt="Cloud"
										loading="lazy"
										className="imgheight"
									></img>
								</div>
								<div
									className="col-12 col-lg-7 dtr-sm-mb-30px wow fadeInLeft"
									data-wow-delay="0.4s"
								>
									<h2 className="title-para left-aligned-title">
										Cloudification
									</h2>
									<br />
									<p className="dtr-mb-5 title-desc left-aligned-desc">
										Cloudification benefits for your business focus on, cloud
										enables you to focus on your core business and leave the
										mundane tasks of setup and and maintenance to the cloud
										infrastructure provider.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section id="iot" className="dtr-sticky-tabs-section margin">
						<div className="container">
							<div className="row2">
								<div
									className="col-12 col-lg-7 dtr-sm-mb-30px wow fadeInRight"
									data-wow-delay="0.4s"
								>
									<h2 className="title-para left-aligned-title">
										IoT Application Development
									</h2>
									<br />
									<p className="dtr-mb-5 title-desc left-aligned-desc">
										We develop apps that work with the sensors and
										microcomputers in wearable devices such as smartwatches,
										health trackers, smart glasses, and AR/VR headsets
									</p>
								</div>
								<div
									className="col-12 col-lg-5 text-center wow fadeInLeft"
									data-wow-delay="0.4s"
								>
									<img
										src={iot}
										alt="iot"
										loading="lazy"
										className="imgheight"
									></img>
								</div>
							</div>
						</div>
					</section>

					<section id="bpa" className="dtr-sticky-tabs-section margin">
						<div className="container">
							<div className="row1">
								<div
									className="col-12 col-lg-5 text-center wow fadeInRight"
									data-wow-delay="0.4s"
								>
									<img
										src={business_automation}
										alt="business automation"
										loading="lazy"
										className="imgheight"
									></img>
								</div>
								<div
									className="col-12 col-lg-7 dtr-sm-mb-30px wow fadeInLeft"
									data-wow-delay="0.4s"
								>
									<h2 className="title-para left-aligned-title">
										Business Process Automation
									</h2>
									<br />
									<p className="dtr-mb-5 title-desc left-aligned-desc">
										Digitise and Automate Assessments, Workflow & Document
										Generation. We build apps that automate business processes &
										workflow.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section id="works" className="bg-blue pb-80">
						<div style={{ padding: '4rem' }}>
							<Carousel />
						</div>
					</section>

					<section className="section_padding_130_80 pb-140 tech" id="tech">
						<div className="container">
							<div className="row">
								<div className="col-md-12 text-center">
									<div className="section-title">
										<h2>
											<u></u>Technologies
										</h2>
									</div>
								</div>
							</div>
							<div className="row frogmen">
								<div className="compscroll">
									<div className="scrollcom">
										<div className="comscrollimg"></div>
										<div className="comscrollimg"></div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="pb-140 my" id="process">
						<div className="container">
							<div className="row">
								<div className="col-md-12 text-center">
									<div className="section-title">
										<h2>Process</h2>
									</div>
								</div>
							</div>
							<div className="row">
								<Lottie options={processOptions} />
							</div>
						</div>
					</section>

					<section className="marginBottom" id="about">
						<div className="section-title">
							<h2>About Us</h2>
						</div>
						<div className="col-lg-12 col-md-12">
							<div className="feature-content container aboutFont">
								{/* <center> */}
								Bytone is an upcoming brand for the development of sophisticated
								web and mobile applications (Native and Hybrid).
								<br />
								<br />
								Our highly capable team, state-of-the-art processes, and
								supportive infrastructure emphasize our dedication to
								cutting-edge engineering solutions and stringent quality
								standards.
								<br />
								<br />
								We specialize in providing premium development and design
								services that fit the challenging requirements of our enterprise
								customers across various industries.
								{/* </center> */}
							</div>
						</div>
					</section>

					<section className="bg-blue pb-80 flex-potfolio pt-100 ">
						<img
							src={portfolio}
							className="col-8 col-lg-4"
							width="400"
							alt="portfolio"
						/>
						<img
							src={portfolio_heading}
							className="col-10 col-lg-6"
							alt="portfolio_heading"
						/>
						<img
							src={portfolio_content}
							className="col-10 col-lg-6"
							alt="portfolio_content"
						/>
					</section>

					<section className="pb-140 my" id="vision">
						<div className="section-title" style={{ marginBottom: 0 }}>
							<h2>Our Vision</h2>
						</div>
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-12 order-1 order-lg-0">
									<div className="feature-img style--two">
										<div
											className="f-bg wow fadeInLeftBig"
											data-wow-duration="1s"
											data-wow-delay=".3s"
											data-wow-offset="100"
										>
											{' '}
											<img
												src={crm_feature_bg}
												className="svg"
												alt="crm-feature-bg"
												loading="lazy"
											></img>
										</div>
										<img
											src={our_vision}
											data-rjs="2"
											className="f-img crm2 wow fadeInUp"
											data-wow-duration="1s"
											data-wow-delay=".7s"
											data-wow-offset="100"
											alt="our-vision"
										></img>
									</div>
								</div>
								<div className="col-lg-6 col-md-12 order-0 order-lg-1">
									<div className="feature-content">
										<div className="feature-content aboutFont">
											Digital advancement continues to rapidly reshape our world
											creating a wide range of opportunities across industries.
											The pace of innovation and change is mind-numbingly fast.
											<br />
											<br />
											Our goal is to encourage businesses and organizations to
											look beyond traditional solutions and align their business
											goals with emerging technologies, putting them ahead of
											competitors.
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section
						className="contact-area section_padding_130_80 pb-140"
						id="contact"
					>
						<div className="section-title">
							<h2>Contact Us</h2>
						</div>
						<ToastProvider placement="bottom-center">
							<Contact />
						</ToastProvider>
					</section>
					<div id="toasts"></div>
				</main>
				<footer id="footer">
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6">
									<div className="footer-info">
										<img
											src={bytone_logo_footer}
											alt="bytone footer logo"
											width="200"
											height="100"
											loading="lazy"
										/>
										<br></br>
										{/* <p className="pb-3 mt-2">
											<em>Delivering tomorrow’s dreams today</em>
										</p> */}

										<div className="social-links mt-3">
											<a
												href="https://facebook.com/bytoneglobal"
												className="facebook"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="bx bxl-facebook"></i>
											</a>
											<a
												href="http://www.instagram.com/bytoneglobal"
												className="instagram"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="bx bxl-instagram"></i>
											</a>
											<a
												href="https://www.linkedin.com/company/bytone"
												target="_blank"
												rel="noopener noreferrer"
												className="linkedin"
											>
												<i className="bx bxl-linkedin"></i>
											</a>
										</div>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 footer-links">
									<h4>Useful Links</h4>
									<ul>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="/#">Home</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#about">About us</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#vision">Our Vision</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#services">Services</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#expertise">Areas of Expertise</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#process">Process</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#tech">Technologies</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#contact">Contact us</a>
										</li>
									</ul>
								</div>

								<div className="col-lg-3 col-md-6 footer-links">
									<h4>Our Services</h4>
									<ul>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#web_dev">Web Application Development</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#static_dev">Static Website Development</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#mobile_dev">Mobile Development</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#ecommerce">E-Commerce Solutions</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#cloudification">Cloudification</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#iot"> IoT Application Development</a>
										</li>
										<li>
											<i className="bx bx-chevron-right"></i>{' '}
											<a href="#bpa">Business Process Automation</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>

				<script
					data-cfasync="false"
					src="../../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
				>
					{' '}
				</script>
				<script src="assets/vendor/jquery/jquery.min.js"></script>
				<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
				<script src="assets/vendor/jquery.easing/jquery.easing.min.js"></script>
				<script src="assets/vendor/php-email-form/validate.js"></script>
				<script src="assets/vendor/owl.carousel/owl.carousel.min.js"></script>
				<script src="assets/vendor/venobox/venobox.min.js"></script>
				<script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
				<script src="assets/vendor/aos/aos.js"></script>
				<script src="assets/plugins/animate/wow.min.js"></script>
				<script src="assets/js/main.js"></script>

				<script>new WOW().init();</script>
			</div>
		)
	}
}
export default App
