import React, { useState } from 'react'

function CarouselMobile({ heading, content, img1, img2 }) {
	const [image, setImage] = useState(0)
	return (
		<div className="row pb-80">
			<div className="col-12 col-lg-5 flex-d ">
				<img src={image === 0 ? img1 : img2} alt={heading} />
			</div>
			<div className="col-12 col-lg-7" style={{ marginTop: '8%' }}>
				<h2 className="col-12 carouselHeading_1">
					<span className="carouselHeading_2">{heading}</span>
				</h2>
				<div className="col-12  mb-20 flex-btn">
					<button
						className={image === 0 ? 'carouselBtn' : 'disabled_button'}
						onClick={() => setImage(0)}
					>
						View 1
					</button>
					{img2 && (
						<button
							className={image === 1 ? 'carouselBtn' : 'disabled_button'}
							onClick={() => setImage(1)}
						>
							View 2
						</button>
					)}
				</div>
				<p className="col-12 carouselFont">{content}</p>
			</div>
		</div>
	)
}

export default CarouselMobile
