import React, { useState } from 'react'
import './carousel.css'

function CarouselImageContainer({ heading, content, mobileImg, desktopImg }) {
	const [variant, setVariant] = useState('desktop')

	return (
		<div className="row pb-80">
			<div className="col-12 col-lg-5 flex-d">
				<img
					src={variant === 'desktop' ? desktopImg : mobileImg}
					alt={heading}
				/>
			</div>
			<div className="col-12 col-lg-7" style={{ marginTop: '10%' }}>
				<h2 className="col-12 text-uppercase carouselHeading_1">
					<span className="carouselHeading_2">{heading}</span>
				</h2>
				<div className="col-12 flex-btn  mb-20">
					<button
						className={variant === 'mobile' ? 'carouselBtn' : 'disabled_button'}
						onClick={() => setVariant('mobile')}
					>
						MOBILE
					</button>
					<button
						className={
							variant !== 'mobile' ? 'carouselBtn ' : 'disabled_button'
						}
						onClick={() => setVariant('desktop')}
					>
						DESKTOP
					</button>
				</div>
				<p className="col-12 carouselFont">{content}</p>
			</div>
		</div>
	)
}

export default CarouselImageContainer
