import React, { Component } from 'react'
import Slider from 'react-slick'
import CarouselImageContainer from './CarouselImageContainer'
import CarouselMobile from './CarouselMobile'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'

// images
import EduMobile from '../assets/img/carousel/EduImg2.png'
import EduDesktop from '../assets/img/carousel/EduImg3.png'
import TA_ECOMMobile from '../assets/img/carousel/TA-Img3.svg'
import TA_ECOMDesktop from '../assets/img/carousel/TA-Img4.png'
import MM1 from '../assets/img/carousel/MM-Img2.png'
import MM2 from '../assets/img/carousel/MM-Img3.png'
import TADesktop from '../assets/img/carousel/TechAstraWeb1 1.png'
import TAMobile from '../assets/img/carousel/Techastra-Ph1 1.png'
// import IOTMobile from '../assets/img/carousel/IOT-Img1.png'
import IOTMobile from '../assets/img/carousel/IOT-Img1.svg'

function SampleNextArrow(props) {
	const { style, onClick } = props
	return (
		<div
			className={'slick-nextArr'}
			style={{
				...style,
			}}
			onClick={onClick}
		/>
	)
}

function SamplePrevArrow(props) {
	const { style, onClick } = props
	return (
		<div className={'slick-previous'} style={{ ...style }} onClick={onClick} />
	)
}

export class Carousel extends Component {
	render() {
		const settings = {
			dots: true,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 0,
			speed: 500,
			arrows: true,
			adaptiveHeight: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			appendDots: (dots) => <ul>{dots}</ul>,
			customPaging: (i) => (
				<div className="ft-slick__dots--custom">
					<div className="loading" />
				</div>
			),
		}
		return (
			<div className="container">
				<h2 className="our_works">Our Works</h2>
				<Slider {...settings}>
					<div className="testimoni--wrapper">
						<CarouselImageContainer
							mobileImg={EduMobile}
							desktopImg={EduDesktop}
							content={
								'Dynamic web portal for education sector built on React Js, Node Js, Express Js.'
							}
							heading={'Edumart'}
						/>
					</div>
					<div className="testimoni--wrapper">
						<CarouselImageContainer
							mobileImg={TAMobile}
							desktopImg={TADesktop}
							heading={'TechAstra Static Website'}
							content={'Static website built on Gatsby Framework.'}
						/>
					</div>
					<div className="testimoni--wrapper1">
						<CarouselImageContainer
							mobileImg={TA_ECOMMobile}
							desktopImg={TA_ECOMDesktop}
							heading={'TechAstra Ecommerce'}
							content={'Ecommerce Web Portal built on the Magento Platform'}
						/>
					</div>
					<div className="testimoni--wrapper">
						<CarouselMobile
							img1={MM1}
							img2={MM2}
							heading={'MATCHMAKING MOBILE APP'}
							content={
								'Dedicated Mobile app for Matchmaking built on react-native framework. '
							}
						/>
					</div>
					<div className="testimoni--wrapper">
						<CarouselMobile
							img1={IOTMobile}
							heading={'Pleth AILytics'}
							content={
								'A health monitoring IoT mobile app provides real-time display of bio-signals captured from wearable devices.'
							}
						/>
					</div>
				</Slider>
			</div>
		)
	}
}

export default Carousel
